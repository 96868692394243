import styled from "styled-components";
import { getColor, TABLET_M_BP } from "@website-builder/utilities-index";

export const TwoColumnWrapper = styled.section`
	${(props) =>
		props.backgroundColor?.type && props.backgroundColor?.type === "custom"
			? `background-color: ${props.backgroundColor?.color};`
			: props.backgroundColor?.type
				? `background-color: ${getColor(props.backgroundColor?.type)};`
				: `background-color: ${getColor(props.backgroundColor)};`}
	.form-container-component {
		[class*="GridWrapper"] {
			margin: 0;
		}
		[class*="ColWrapper"] {
			grid-column: 1/13;
		}
	}

	@media only screen and (max-width: ${TABLET_M_BP}px) {
		margin: 0 12px;
	}
`;
