import React from "react";
import { TwoColumnWrapper } from "./style";
import { Grid, Col } from "@website-builder/ui/shared/elements";

const TwoColumnSection = (props) => {
	const {
		hashLink = "",
		leftSection = [],
		rightSection = [],
		leftHashLink = "",
		rightHashLink = "",
		backgroundColor = "#FFFFFF",
		split = 6,
		align = false,
		order = "left",
	} = props;

	let leftOrder = 1;
	let rightOrder = 2;
	if (order === "right") {
		rightOrder = 1;
		leftOrder = 2;
	}

	return (
		<TwoColumnWrapper
			backgroundColor={backgroundColor}
			id={hashLink ? `${hashLink}` : ""}
			className={`lazyload row`}
		>
			<Grid
				className="grid-container"
				vAlign={align ? "center" : null}
				hMargin={[0, 0]}
			>
				<Col
					span={split}
					mdStart={1}
					mdSpan={6}
					className="2-col-left-section"
					id={leftHashLink ? leftHashLink : ""}
					order={leftOrder}
				>
					{leftSection}
				</Col>
				<Col
					span={12 - split}
					mdStart={1}
					mdSpan={6}
					id={rightHashLink ? rightHashLink : ""}
					className="2-col-right-section"
					order={rightOrder}
				>
					{rightSection}
				</Col>
			</Grid>
		</TwoColumnWrapper>
	);
};

export default TwoColumnSection;
